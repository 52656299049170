<template>
  <div id="round-number-rate-setting" class="container-fluid">
    <div class="page-header mb-1">
      <h4>ตั้งค่าอัตราจ่าย</h4>
    </div>

    <div v-if="roundId" class="card">
      <div class="card-header">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
        </h5>
      </div>
      <div class="card-body">

        <div class="mb-4">
          <h5>
            อัตราจ่าย
            <span class="text-secondary" style="text-decoration: underline;">(เฉพาะงวด)</span>
            วันที่ <span class="text-primary">{{ roundDate }}</span>
            <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบด้านบน)</small>
          </h5>

          <b-tabs active-nav-item-class="font-weight-bold text-success">
            <b-tab title-link-class="text-secondary" :active="activeTab==='normal'" @click="activeTab='normal'">
              <template #title>
                <i v-if="rateType==='normal'" class="fas fa-check-circle text-success" /> อัตราจ่ายปกติ
              </template>
            </b-tab>
            <b-tab title-link-class="text-secondary" :active="activeTab==='steprate'" @click="activeTab='steprate'">
              <template #title>
                <i v-if="rateType==='steprate'" class="fas fa-check-circle text-success" /> อัตราจ่ายแบบลดขั้น
              </template>
            </b-tab>
            <b-tab title-link-class="text-secondary" :active="activeTab==='fixrate'" @click="activeTab='fixrate'">
              <template #title>
                <i v-if="rateType==='fixrate'" class="fas fa-check-circle text-success" /> อัตราจ่ายแบบ Fix Rate
              </template>
            </b-tab>
          </b-tabs>

          <div class="mt-2" style="min-height: 120px;">
            <div v-if="activeTab==='fixrate'">
              <div class="alert alert-info mb-1">
                *** อัตราจ่ายแบบ "<strong>Fix Rate</strong>" คือการใช้ราคาจ่ายเหมือนกันทั้งหมดในหวยงวดนี้ <u>ยกเว้นอัตราจ่ายรายตัว</u>
              </div>
              <RoundFixrate :data="round" @reload="loadRoundData" />
            </div>

            <div v-else-if="activeTab==='steprate'">
              <div class="alert alert-info mb-1">
                *** อัตราจ่ายแบบ "<strong>อัตราจ่ายแบบลดขั้น</strong>" คือการลดราคาจ่ายตามยอดรับของบริษัท <u>ยกเว้นอัตราจ่ายรายตัว</u>
              </div>
              <RoundStepRate :market="market" :round="round" @reload="loadRoundData" />
            </div>

            <div v-else>
              <div class="alert alert-info mb-1">
                *** "<strong>อัตราจ่ายปกติ</strong>" ใช้อัตราจ่ายที่เอเย่นต์เปิดให้กับสายงาน
              </div>
              <RoundNormalRate :data="round" @reload="loadRoundData" />
            </div>
          </div>

        </div>

        <div>
          <h5>
            อัตราจ่ายรายตัว
            <span class="text-secondary" style="text-decoration: underline;">(เฉพาะงวด)</span>
            วันที่ <span class="text-primary">{{ roundDate }}</span>
            <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบด้านบน)</small>
          </h5>
          <div class="row">
            <div v-for="bet in marketOpenBets" :key="bet.code" class="col-md-4">
              <NumberRate :bet="bet" :data="round" @reload="loadRoundData" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"

import RoundService from "@/services/RoundService"
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'
import NumberRate from './components/NumberRate'
import RoundFixrate from './components/RoundFixrate'
import RoundStepRate from './components/RoundStepRate'
import RoundNormalRate from './components/RoundNormalRate'

export default {
  name: 'NumbersRate',
  components: {
    NumberRate,
    RoundFixrate,
    RoundStepRate,
    RoundNormalRate
  },
  data() {
    return {
      data: null,
      isProcess: false,
      activeTab: null
    }
  },
  computed: {
    market() {
      return this.data?.market
    },
    marketOpenBets() {
      return marketOpenBets.filter((m)=>{
        return this.market?.openBets?.[m.code]
      })
    },
    round() {
      if(!this.data)
        return null

      return this.data.round
    },
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    rateType() {
      return this.round?.useRate?.rateType ?? 'normal'
    }
  },
  watch: {
    roundId() {
      this.loadRoundData()
    }
  },
  methods: {
    loadRoundData() {
      if(!this.roundId)
        return

      RoundService.getRoundSetting(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          if(response?.data?.market?.marketType === 'lottoset') {
            this.$router.push({name: 'LottosetSetting'})
          }else{
            this.activeTab = response?.data?.round?.useRate?.rateType ?? 'normal'
            console.log('this.activeTab', this.activeTab)
          }
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.loadRoundData()
  }
}
</script>
